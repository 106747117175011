import Blockly, { FieldImage } from "blockly";
import { HTMLGenerator, codeGenerator } from "../HTMLGenerator.js";
import { addComposeToSingleBlockField } from "./htmlBlock.js";
import { makeMenu, menu } from "../menus.js";

const generateToolboxCategoryForElement = (elementName: string) => {
  const category = document.createElement("category");
  category.setAttribute("name", elementName);
  return category;
};

const generateGenericAttributeAndElementBlocks = () => {
  const category = generateToolboxCategoryForElement("generic");
  const genericAttributeXML = document.createElement("block");
  const genericElementXML = document.createElement("block");

  genericAttributeXML.setAttribute("type", "generic-element-attribute");
  genericElementXML.setAttribute("type", "generic-element");

  category.appendChild(genericAttributeXML);
  category.appendChild(genericElementXML);

  // create the attribute block

  const defGenericAttribute = {
    type: "genericAttribute",

    previousStatement: "attribute",
    nextStatement: "attribute",
    colour: 190,
  };

  Blockly.Blocks["generic-element-attribute"] = {
    def: defGenericAttribute,

    init: function () {
      this.jsonInit(defGenericAttribute);
      this.setOutput(false, "attribute");

      // addComposeToSingleBlockField(this);

      const dummyInput = this.appendDummyInput();

      dummyInput.appendField(new Blockly.FieldTextInput(), "attribute");
      dummyInput.appendField("=", "equalSign");
      dummyInput.appendField(new Blockly.FieldTextInput(), "value");
    },
  };

  const defGenericElement = {
    type: "genericHtmlElement",

    previousStatement: "htmlElement",
    nextStatement: "htmlElement",
    colour: 190,
  };

  Blockly.Blocks["generic-element"] = {
    def: defGenericElement,

    init: function () {
      this.jsonInit(defGenericElement);
      this.setOutput(false, "htmlElement");

      addComposeToSingleBlockField(this);
      // const dummyOne = this.appendDummyInput().appendField(new Blockly.FieldImage("/dist/icons/left-top-arrow-circle.svg", 24, 24, "compose to single block", () =>  setTimeout(()=>composeToSingleBlockAndAttach(this))))

      // produce a label that contains the element's name
      const dummyInput = this.appendDummyInput("tagName");
      dummyInput.appendField("tagName:");
      dummyInput.appendField(new Blockly.FieldTextInput(), "tagName");

      // statementInput for attributes
      this.appendStatementInput("attributes")
        .appendField("attributes:")
        .setCheck(["attribute"]);

      // statementInput for content
      this.appendStatementInput("content")
        .appendField("content:")
        .setCheck(["htmlElement"]);

      // add child button
      this.appendDummyInput("addChild")
        .setAlign(Blockly.ALIGN_RIGHT)
        .appendField(
          new Blockly.FieldImage(
            "/dist/icons/plus-circle.svg",
            24,
            24,
            "break down to blocks",
            (e: FieldImage) => {
              console.log(e);

              setTimeout(() => {
                console.log("plus!");
                const menuElement = makeMenu(
                  menu,
                  e.imageElement_.getBoundingClientRect()
                );
                document.body.appendChild(menuElement);
              });
            },

            false,
            {}
          )
        );
    },
  };

  const gen = HTMLGenerator as any;
  gen["generic-element"] = codeGenerator("generic-element");
  gen["generic-element-attribute"] = codeGenerator("generic-element-attribute");

  return category;
};

const generateTextBlock = () => {
  const category = generateToolboxCategoryForElement("text");
  const textNodeXML = document.createElement("block");
  textNodeXML.setAttribute("type", "textNode");
  category.appendChild(textNodeXML);
  const defTextNode = {
    type: "textNode",
    previousStatement: "htmlElement",
    nextStatement: "htmlElement",
    colour: 190,
  };

  Blockly.Blocks["textNode"] = {
    def: defTextNode,
    init: function () {
      this.jsonInit(defTextNode);
      this.setOutput(false, "htmlElement");
      // produce a label that contains the element's name
      const dummyInput = this.appendDummyInput();
      dummyInput.appendField("text:");
      dummyInput.appendField(new Blockly.FieldMultilineInput(), "content");
    },
  };

  const gen = HTMLGenerator as any;
  gen["textNode"] = codeGenerator("textNode");

  return category;
};

export { generateGenericAttributeAndElementBlocks, generateTextBlock };
