import { html } from "../util/html";

const response_items = [
  "aa-text-answer",
  "aa-likert-scale",
  "aa-slider",
  "aa-multiple-choice",
  "aa-checkboxes",
];

const logic = ["aa-sequence", "aa-choose", "aa-variable"];

export const menu = [{ response_items }, { logic }];

export const makeMenu = (menu: Array<any>, position: any) => {
  console.log(makeMenuHTML(menu));
  const menuElement = document.createElement("div");
  menuElement.innerHTML = makeMenuHTML(menu);
  menuElement.addEventListener("click", (e: Event) => {
    console.log(
      "selection = ",
      (e?.target as HTMLElement)?.dataset["selection"]
    );
  });

  menuElement.setAttribute(
    "style",
    `position:absolute; top:${position.top}px; left:${position.left}px;`
  );

  return menuElement;
};

const makeMenuHTML = (menu: Array<any>) => {
  let menuHTML = `<ul class="dropdown-menu">`;
  menu.forEach((item) => {
    if (typeof item === "string") {
      //plain menu item
      menuHTML += html`<li class="dropdown-item" data-selection="${item}">
        ${item}
      </li>`;
    } else if (typeof item === "object") {
      const key = Object.keys(item)[0];
      const menu = item[key];
      menuHTML += `<li class="dropdown-item">${key}`;
      menuHTML += makeMenuHTML(menu);
      menuHTML += "</li>";
      //has a key for menu item and an array for a submenu
    }
  });

  menuHTML += html`</ul>`;

  return menuHTML;
};
