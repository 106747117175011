/* eslint-disable @typescript-eslint/no-unused-vars */
import Blockly, { Block, FieldTextInput } from "blockly";
import { generateToolboxCategoryForElement } from "./toolboxCategory";
import { HTMLGenerator, codeGenerator } from "../HTMLGenerator.js";
import { HTMLField } from "../../inputFields/htmlField/htmlField";
import { html } from "../../util/html";

export const addComposeToSingleBlockField = (block: Blockly.Block) => {
  return block
    .appendDummyInput()
    .appendField(
      new Blockly.FieldImage(
        "/dist/icons/left-top-arrow-circle.svg",
        24,
        24,
        "compose to single block",
        () => setTimeout(() => composeToSingleBlockAndAttach(block)),
        false,
        {}
      )
    );

  // this would be an alternative way to add a click listener
  // f.sourceBlock_.svgGroup_.addEventListener("click", (e) => {
  //     e.stopPropagation();
  //     composeToSingleBlockAndAttach(block);
  // });
};

export const composeToSingleBlockAndAttach = (block: Blockly.Block) => {
  const htmlBlock = composeToHTMLBlock(block);
  htmlBlock.initModel();
  htmlBlock.initSvg();
  htmlBlock.render();
  if (block.parentBlock_) {
    // block.parentBlock_.getInput("content")?.connection.connect(htmlBlock.previousConnection);
    block.previousConnection.targetConnection.connect(
      htmlBlock.previousConnection
    );
  }

  block.dispose(true, false);
};

const composeToHTMLBlock = (block: Block) => {
  // make sure generator is initialized
  const workspace = Blockly.getMainWorkspace();
  HTMLGenerator.init(workspace);

  const code = codeGenerator()(block);
  // const workspace = Blockly.getMainWorkspace();
  const htmlBlock = workspace.newBlock("htmlBlock");
  const contentInput = htmlBlock.getInput("content");
  if (contentInput) {
    (contentInput.fieldRow[0] as HTMLField).htmlContent = code;
  }

  return htmlBlock;
};

const isAAElement = (name: string) => {
  return name.indexOf("aa-") === 0 ? true : false;
};

const listAttributes = (element: HTMLElement) => {
  const result: any = [];

  if (element.hasAttributes()) {
    const attrs = element.attributes;

    for (let i = attrs.length - 1; i >= 0; i--) {
      result.push({ name: attrs[i].name, value: attrs[i].value });
    }
  }
  return result;
};

const createBlock = (type: string) => {
  // console.log("type", type);
  const workspace = Blockly.getMainWorkspace();
  const block = workspace.newBlock(type);

  block.initModel();
  block.initSvg();
  block.render();

  console.log(workspace.getUndoStack());
  return block;
};
const createBlocksFromHTML = (htmlText: string) => {
  const workspace = Blockly.getMainWorkspace();

  const blocks = [];
  const items = [];

  const container = document.createElement("div");
  container.innerHTML = htmlText.trim();

  for (let i = 0; i < container.childNodes.length; i++) {
    const childNode = container.childNodes[i];

    console.log("making", childNode.nodeName);

    if (childNode.nodeType == document.ELEMENT_NODE) {
      const child = childNode as HTMLElement;
      const type = isAAElement(child.tagName.toLowerCase())
        ? child.tagName.toLowerCase()
        : "generic-element";

      const elementBlock = createBlock(type);
      elementBlock
        .getInput("tagName")
        ?.fieldRow[1].setValue(child.tagName.toLowerCase());

      // also create and attach attributes;
      const attrs = listAttributes(child);
      let lastAttributeBlock: Blockly.Block;
      attrs.forEach((attr: any, index: number) => {
        console.log(attr.name);
        const genericAttributeBlock = createBlock("generic-element-attribute");
        genericAttributeBlock.getField("attribute")?.setValue(attr.name);
        genericAttributeBlock.getField("value")?.setValue(attr.value);
        if (index === 0) {
          elementBlock
            .getInput("attributes")
            ?.connection.connect(genericAttributeBlock.previousConnection);
        } else {
          lastAttributeBlock.nextConnection.connect(
            genericAttributeBlock.previousConnection
          );
        }

        lastAttributeBlock = genericAttributeBlock;
      });

      if (
        child.childNodes.length == 1 &&
        child.childNodes[0].nodeType === document.TEXT_NODE
      ) {
        // create and atach text block
        const textBlock = createBlock("textNode");
        textBlock.getField("content")?.setValue(childNode.textContent);
        elementBlock
          .getInput("content")
          ?.connection.connect(textBlock.previousConnection);
      } else {
        // create a custom block with the element's innerHTML, to attach to contentInput
        const contentBlock = createBlock("htmlBlock");
        const field = contentBlock.getInput("content")?.fieldRow[0] as any;
        field.htmlContent = (child as HTMLElement).innerHTML.trim();
        field.display_.style.height = "fit-content";
        field.renderDisplay();
        field.updateSize_();
        elementBlock
          .getInput("content")
          ?.connection.connect(contentBlock.previousConnection);
      }

      blocks.push(elementBlock);
      items.push(type);
    }

    if (childNode.nodeType == document.TEXT_NODE) {
      if (childNode.textContent?.trim()) {
        const textBlock = createBlock("textNode");
        textBlock.getField("content")?.setValue(childNode.textContent);
        blocks.push(textBlock);
        items.push("text");
      }
    }
  }

  console.log(items);
  return blocks;
};

export const generateHtmlBlock = () => {
  const category = generateToolboxCategoryForElement("custom1");
  const htmlBlockXML = document.createElement("block");
  htmlBlockXML.setAttribute("type", "htmlBlock");
  category.appendChild(htmlBlockXML);
  const defTextNode = {
    type: "htmlBlock",
    previousStatement: "htmlElement",
    nextStatement: "htmlElement",
    colour: 190,
  };

  Blockly.Blocks["htmlBlock"] = {
    def: defTextNode,
    init: function () {
      this.jsonInit(defTextNode);
      this.setOutput(false, "htmlElement");
      // produce a label that contains the element's name

      // this.appendDummyInput()
      // .appendField('simple turtle and some more text');

      // const dummyInput = this.appendDummyInput();

      // dummyInput.appendField(new Blockly.FieldMultilineInput(), 'content');
      // dummyInput.appendField(new GenericField(), 'content');
      // dummyInput.appendField(new FieldTextInputExperiment(), 'content2');

      // const imageField = new CustomUIInputField();
      // imageField.setContent(myUI);

      // dummyInput.appendField(imageField, 'content2');

      // const turtleField = new FieldTurtle("Dots", "Crown", "Yertle");
      // dummyInput.setAlign(Blockly.ALIGN_CENTRE).appendField(turtleField, 'turtle');
      // this.setStyle('loop_blocks');

      // setTimeout(() => {
      //     const w = Blockly.getMainWorkspace()
      //     console.log(w.getUndoStack());
      //     console.log(w.getRedoStack());
      // },100)

      // const breadDownField = this.appendDummyInput().appendField("breakdown")

      // breadDownField.sourceBlock_.svgGroup_.addEventListener("click", (e: Event) => {

      const onClickListener = () => {
        // e.stopPropagation();
        console.log("breakdown!!");
        console.log(htmlField.htmlContent);
        console.log(this);

        const blocks = createBlocksFromHTML(htmlField.htmlContent);
        const coordinates = this.getBoundingRectangle();

        for (let i = 0; i < blocks.length; i++) {
          try {
            blocks[i].moveBy(coordinates.left, coordinates.top);
          } catch (e) {}
        }

        console.log();

        if (this.parentBlock_) {
          // this.parentBlock_.getInput("content")?.connection.connect(blocks[0].previousConnection);
          this.previousConnection.targetConnection.connect(
            blocks[0].previousConnection
          );
          for (let i = 0; i < blocks.length - 1; i++) {
            blocks[i].nextConnection.connect(blocks[i + 1].previousConnection);
          }
        }

        this.dispose(true, false);

        setTimeout(() => {
          const w = Blockly.getMainWorkspace();
          console.log(w.getUndoStack());

          // TODO : how to handle the undo stack? Perhaps it's possible to create all blocks as once as single XML string?

          // w.getUndoStack().pop();
          // w.undoStack_ = w.undoStack_.splice()
          // w.undoStack_ = w.undoStack_.splice()
          // console.log(w.getUndoStack());

          // console.log(w.getRedoStack());
        }, 0);

        // this.checkAndDelete();
      };

      this.appendDummyInput().appendField(
        new Blockly.FieldImage(
          "/dist/icons/right-down-arrow-circle.svg",
          24,
          24,
          "break down to blocks",
          () => setTimeout(() => onClickListener()),
          false,
          {}
        )
      );

      const htmlField = new HTMLField(
        html`<div style="border: solid thin">contains html</div>`
      );
      this.appendDummyInput("content")
        .setAlign(Blockly.ALIGN_CENTRE)
        .appendField(htmlField);
    },
  };

  const gen = HTMLGenerator as any;
  gen["htmlBlock"] = codeGenerator("htmlBlock");

  return category;
};
