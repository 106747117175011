import { AAElements } from "executable_html";
import { generateBlocksForElement } from "./blocks/elementBlocks";
import {
  generateGenericAttributeAndElementBlocks,
  generateTextBlock,
} from "./blocks/genericBlocks";

import { generateHtmlBlock } from "./blocks/htmlBlock";

const generateToolbox = (elementSet: { [x: string]: any }) => {
  elementSet;
  console.log(Object.keys(elementSet));
  const toolbox = document.createElement("xml");
  toolbox.setAttribute("id", "toolbox");
  for (const i in elementSet) {
    if (i == "BaseElement") continue;

    const element = elementSet[i];
    const category = generateBlocksForElement(element, element.tag);
    toolbox.appendChild(category);
  }
  toolbox.appendChild(generateGenericAttributeAndElementBlocks());
  toolbox.appendChild(generateTextBlock());

  toolbox.appendChild(generateHtmlBlock());

  return toolbox;
};

export const tools = generateToolbox(AAElements);

// let blockStyles = {
//     "list_blocks": {
//         "colourPrimary": "#4a148c",
//         "colourSecondary": "#AD7BE9",
//         "colourTertiary": "#CDB6E9"
//     }
// };
// let categoryStyles = {
//     "list_category": {
//         "colour": "#4a148c"
//     }
// }
// let componentStyles = {
//     'workspaceBackgroundColour': '#1e1e1e'
// }
// let theme = Blockly.Theme.defineTheme('themeName', {
//     'blockStyles': blockStyles,
//     'categoryStyles': categoryStyles,
//     'componentStyles': componentStyles
// })

// Blockly.Blocks["htmlElement"] = {
//     init: function () {
//         this.jsonInit(htmlElement_defitition);
//         this.setColour(10);

//         // this.appendStatementInput('GENERIC_ATTRIBUTES')
//         //     .setCheck('generic_attribute')
//         //     .appendField('generic_attribute');
//         // // this.setTooltip(this.getFieldValue("NAME")  + " lalalal");

//     }

// }
