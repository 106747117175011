"use strict";

import Blockly, { Bubble } from "blockly";

declare const document: Document;

export class GenericField extends Blockly.Field {
  workspace_: Blockly.Workspace | null = null;

  static fromJson(options: any): Blockly.Field {
    const value = Blockly.utils.replaceMessageReferences(options["value"]);
    return new GenericField(value);
  }

  constructor(opt_value?: any, opt_validator?: any) {
    super(opt_value, opt_validator);
    opt_value = this.doClassValidation_(opt_value);
    if (opt_value === null) {
      opt_value = this.DEFAULT_VALUE;
    } // Else the original value is fine.
  }

  public initView(): void {
    super.initView();
    // this.createTextElement_();
    this.createBorderRect_();
  }

  textElement_: any;

  createTextElement_() {
    console.log("createTextElement!!!");
    // this.textElement_ = Blockly.utils.dom.createSvgElement(
    //     Blockly.utils.Svg.TEXT, {
    //       'class': 'blocklyText',
    //     },
    //     this.fieldGroup_);
    // // if (this.getConstants().FIELD_TEXT_BASELINE_CENTER) {
    // //   this.textElement_.setAttribute('dominant-baseline', 'central');
    // // }
    // this.textContent_ = document.createTextNode('');
    // this.textElement_.appendChild(this.textContent_);

    const foreignObject = document.createElement("foreignobject");
    foreignObject.setAttribute("height", "50");
    foreignObject.setAttribute("width", "500");
    foreignObject.innerHTML = `<div xmlns="http://www.w3.org/1999/xhtml">shit</div>`;
    // return foreignObject;

    this.textElement_ = foreignObject;

    return this.textElement_;

    // this.textElement_ = document.createElement("foreignobject");
    // this.textElement_
  }

  createBorderRect_() {
    console.log("createBorderRect!!");
    this.borderRect_ = Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.RECT,
      {
        rx: this.getConstants()?.FIELD_BORDER_RECT_RADIUS,
        ry: this.getConstants()?.FIELD_BORDER_RECT_RADIUS,
        x: 0,
        y: 0,
        height: this.size_.height,
        width: this.size_.width,
        background: "blue",
        class: "blocklyFieldRect",
      },
      this.fieldGroup_
    );

    const foreignObject = document.createElement("foreignobject");
    foreignObject.setAttribute("height", "500");
    foreignObject.setAttribute("width", "500");
    foreignObject.innerHTML = `<div xmlns="http://www.w3.org/1999/xhtml">shit</div>`;

    this.borderRect_.appendChild(foreignObject);
    this.borderRect_.classList.add("nikos");
  }

  // public getText() {
  //   const text = `<foreignobject><div style="color="red">shit</div></foreignobject>`;

  //   return text;
  // }

  // public showEditor_() {
  //   // Create the widget HTML
  //   this.editor_ = this.dropdownCreate_();
  //   Blockly.DropDownDiv.getContentDiv().appendChild(this.editor_);

  //   // Set the dropdown's background colour.
  //   // This can be used to make it match the colour of the field.
  //   Blockly.DropDownDiv.setColour('white', 'silver');

  //   // Show it next to the field. Always pass a dispose function.
  //   Blockly.DropDownDiv.showPositionedByField(
  //       this, this.disposeWidget_.bind(this));
  // };

  htmlInput_: any = null;
  isBeingEdited_ = false;
  editor_: any;

  // showEditor_() {
  //   // Create the widget HTML
  //   this.editor_ = this.widgetCreate_();
  //   Blockly.DropDownDiv.getContentDiv().appendChild(this.editor_);

  //   // Set the dropdown's background colour.
  //   // This can be used to make it match the colour of the field.
  //   Blockly.DropDownDiv.setColour('white', 'silver');

  //   // Show it next to the field. Always pass a dispose function.
  //   Blockly.DropDownDiv.showPositionedByField(
  //       this, this.widgetDispose_.bind(this));
  // }

  //  showEditor_(_opt_e: any, opt_quietInput: boolean) {
  //    console.log("showEditor!!")
  //     this.workspace_ = (/** @type {!BlockSvg} */ (this.sourceBlock_)).workspace;
  //     const quietInput = opt_quietInput || false;
  //     // if (!quietInput &&
  //     //     (userAgent.MOBILE || userAgent.ANDROID || userAgent.IPAD)) {
  //     //   this.showPromptEditor_();
  //     // } else {
  //       this.showInlineEditor_(quietInput);
  //     // }
  //   }

  showInlineEditor_(quietInput: any) {
    Blockly.WidgetDiv.show(
      this,
      this.sourceBlock_.RTL,
      this.widgetDispose_.bind(this)
    );
    this.htmlInput_ = this.widgetCreate_();
    this.isBeingEdited_ = true;

    // if (!quietInput) {
    //   this.htmlInput_.focus({preventScroll: true});
    //   this.htmlInput_.select();
    // }
  }

  widgetDispose_() {
    // Non-disposal related things that we do when the editor closes.
    this.isBeingEdited_ = false;
    // this.isTextValid_ = true;
    // Make sure the field's node matches the field's internal value.
    this.forceRerender();
    // TODO(#2496): Make this less of a hack.
    // if (this.onFinishEditing_) {
    //   this.onFinishEditing_(this.value_);
    // }
    // eventUtils.setGroup(false);

    // Actual disposal.
    // this.unbindInputEvents_();
    const style = (Blockly.WidgetDiv.getDiv() as any).style;
    style.width = "auto";
    style.height = "auto";
    style.fontSize = "";
    style.transition = "";
    style.boxShadow = "";
    this.htmlInput_ = null;

    Blockly.utils.dom.removeClass(this.getClickTarget_(), "editing");
  }

  widgetCreate_() {
    console.log("wigdgetCreate!!!");
    const div: any = Blockly.WidgetDiv.getDiv();

    div.classList.add("nikos");
    const html = document.createElement("div");
    html.innerHTML = `<div style="color:red">shit</div>`;

    div.appendChild(html);

    // this.bindInputEvents_(html);

    return html;
  }
}

Blockly.fieldRegistry.register("field_generic", GenericField);
