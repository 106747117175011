import Blockly from "blockly";
import { BaseField } from "../BaseField";
import { html } from "../../util/html";
import { v4 as uuidv4 } from "uuid";

export class HTMLField extends BaseField {
  contentId = uuidv4();

  static fromJson(options: any) {
    return new HTMLField(options.value);
  }

  constructor(content?: string) {
    super();
    this.value_ = "";
    // this.value_ = opt_value;

    // this.htmlContent = `
    // <aa-screen id="${this.contentId}">
    // <h1>hello</h1>
    // <aa-multiple-choice name=${this.value_}>
    //     <aa-choice-item> one </aa-choice-item>
    //     <aa-choice-item> two </aa-choice-item>
    //     <aa-choice-item> three </aa-choice-item>
    // </aa-multiple-choice>
    // </aa-screen>
    // `;

    // this.htmlContent = html`
    // <div id="${this.contentId}" >
    // <h1 id="h1" class="my class" data-yo="hey">hello</h1>
    // <aa-multiple-choice >
    //     <aa-choice-item> one </aa-choice-item>
    //     <aa-choice-item> two </aa-choice-item>
    //     <aa-choice-item> three </aa-choice-item>
    // </aa-multiple-choice>
    // </div>
    // `;

    // this.htmlContent = html` <aa-screen
    //   name="idScreen"
    //   submit-button-text="Next"
    // >
    //   <p>
    //     Welcome! This is the first time you use this phone to log in.
    //     <aa-label>Please enter your participant number</aa-label>
    //     <aa-text-answer
    //       type="number"
    //       name="participantNumber"
    //       label="participant number"
    //     >
    //     </aa-text-answer>
    //   </p>
    //   <p>
    //     <aa-label>Please indicate your gender:</aa-label>
    //     <aa-multiple-choice name="gender">
    //       <aa-choice-item> Male </aa-choice-item>
    //       <aa-choice-item> Female </aa-choice-item>
    //     </aa-multiple-choice>
    //   </p>
    //   <p>this is a very nice third paragraph</p>
    // </aa-screen>`;

    // console.log(this.contentId);
    this.htmlContent = content || "";
  }

  renderDisplay() {
    super.renderDisplay();

    this.display_.innerHTML = this.htmlContent;
    this.updateSize_();
  }

  toArray(s: any) {
    const result = [];
    for (let i = 0; i < s.length; i++) {
      result.push(s[i]);
    }
    return result;
  }

  renderEditor() {
    const genericEditor = document.createElement("generic-editor") as any;
    genericEditor.for = this.contentId;

    this.editor_.innerHTML = "";
    // this.editor_.appendChild(genericEditor);
  }
}

Blockly.fieldRegistry.register("field_html", HTMLField);
