import Blockly from "blockly";

export class BaseField extends Blockly.Field {
  foreignObject = document.createElementNS(
    "http://www.w3.org/2000/svg",
    "foreignObject"
  );

  // myGroup = document.createElementNS('http://www.w3.org/2000/svg', 'g');

  editorContainer_: any;
  editor_ = document.createElementNS("http://www.w3.org/1999/xhtml", "div");
  display_ = document.createElementNS("http://www.w3.org/1999/xhtml", "div");

  htmlContent = "";

  constructor(opt_value?: string) {
    super(opt_value);
    this.value_ = opt_value;
    this.EDITABLE = true;
    this.SERIALIZABLE = true;
  }

  static fromJson(options: any) {
    return new BaseField(options.value);
  }

  protected render_(): void {
    super.render_();
    this.updateSize_();
    // this.showEditor();
  }

  protected configure_(config: any): void {
    super.configure_(config);
  }

  initView(): void {
    // this will create a this.textElement_ and a this.borderRect_
    super.initView();
    // this.borderRect_.appendChild(this.myGroup);

    this.fieldGroup_.appendChild(this.foreignObject);
    this.foreignObject.appendChild(this.display_);

    // this.foreignObject.setAttribute("y", this.textElement_.getAttribute("height") || "0");

    this.foreignObject.style.display = "block";

    this.display_.style.width = "fit-content";
    this.display_.style.height = "fit-content";
    this.display_.style.paddingLeft = "8px";
    this.display_.style.paddingTop = "8px";
    // if the border is not set, getBoundingClientRect does not always reflect the correct height for the node
    this.display_.style.border = "solid thin rgba(0,0,0,0)";

    this.renderDisplay();
  }

  renderDisplay() {
    // do nothing
  }

  renderEditor() {
    // do nothing
  }

  updateSize_() {
    super.updateSize_();

    const textBox = this.textElement_.getBoundingClientRect();

    // this.foreignObject.setAttribute("y", String(textBox.bottom));
    const bbox = this.display_.getBoundingClientRect();

    const width = Math.max(
      bbox.width,
      this.textElement_.getBoundingClientRect().width + 10
    );
    const height = bbox.height; // + textBox.bottom;

    if (this.borderRect_) {
      // width += this.constants_.FIELD_BORDER_RECT_X_PADDING * 4;
      // height += this.constants_.FIELD_BORDER_RECT_X_PADDING * 2;

      this.borderRect_.setAttribute("width", "" + width);
      this.borderRect_.setAttribute("height", "" + height);
    }
    // // Note how both the width and the height can be dynamic.
    this.size_.width = width;
    this.size_.height = height;
    this.foreignObject.setAttribute("width", "" + width);
    this.foreignObject.setAttribute("height", "" + height);
  }

  // Called when the field is clicked. It is usually used to show an editor,
  // but it can also be used for other things e.g. the checkbox field uses
  // this function to check/uncheck itself.
  showEditor_() {
    this.editorContainer_ = Blockly.DropDownDiv.getContentDiv();
    this.editorContainer_.style.fontFamily = "Roboto";
    this.editorContainer_.appendChild(this.editor_);
    this.renderEditor();
    console.log(this.editor_);

    let border = this.sourceBlock_.style.colourTertiary;
    border = border.colourBorder || border.colourLight;
    Blockly.DropDownDiv.setColour(this.sourceBlock_.getColour(), border);

    Blockly.DropDownDiv.showPositionedByField(this, this.dropDownDispose_);

    // how to
    this.editor_.style.minHeight = "none";
    this.editor_.style.maxHeight = "none";
    this.editor_.style.height = "none";
    this.editor_.id = "editoooor";
  }

  dropDownDispose_() {
    return;
  }

  getElementConfig(element: any) {}
}

// Blockly.fieldRegistry.register('field_switch', FieldSwitch);
