import Blockly from "blockly";
import { generateToolboxCategoryForElement } from "./toolboxCategory";
import { HTMLGenerator, codeGenerator } from "../HTMLGenerator";
import { addComposeToSingleBlockField } from "./htmlBlock.js";
import * as colorConvert from "color-convert";
import { pastels } from "../../pastelColours";

const hsv = (h: number, s: number, v: number) => {
  return { h, s, v };
};

const colorForTag: any = {
  "aa-checkboxes": hsv(80, 26, 89),
  "aa-multiple-choice": hsv(80, 26, 89),
  "aa-affect-grid": hsv(80, 26, 89),
  "aa-choice-item": hsv(80, 26, 89),
  "aa-text-answer": hsv(80, 26, 89),
  "aa-slider": hsv(80, 26, 89),
  "aa-likert-scale": hsv(80, 26, 89),
  "aa-geolocation": hsv(80, 26, 89),

  "aa-screen": hsv(285, 18, 87),

  "aa-choose": hsv(210, 8, 75),
  "aa-when": hsv(210, 8, 75),
  "aa-otherwise": hsv(210, 8, 75),
  "aa-sequence": hsv(210, 8, 75),
};

const generateAttributeBlockForElement = (
  element: { properties: { [x: string]: any } },
  elementName: string,
  propName: string
) => {
  if (propName.indexOf("diagram") != -1) {
    return;
  }
  if (!element.properties[propName].userDefined) {
    return;
  }

  const blockName = `${elementName}-${propName}`;
  const blockXML = document.createElement("block");

  blockXML.setAttribute("type", blockName);

  const def = {
    type: "attribute",
    previousStatement: "attribute",
    nextStatement: "attribute",
    colour: 190,
  };

  Blockly.Blocks[blockName] = {
    def: def,

    init: function () {
      this.jsonInit(def);
      // this.setOutput(true, "attribute");
      this.setInputsInline(false);

      // const valInput = this.appendValueInput('attributes').setCheck(["attribute"]).setAlign(Blockly.ALIGN_RIGHT);
      const dummyInput = this.appendDummyInput();
      const prop = element.properties[propName];
      if (prop.userDefined) {
        dummyInput.appendField(propName + "=");
        let fieldToAppend;
        switch (prop.type) {
          case String:
            fieldToAppend = new Blockly.FieldTextInput(prop.value);
            break;
          case Number:
            fieldToAppend = new Blockly.FieldNumber(prop.value);
            break;
          case Boolean:
            fieldToAppend = new Blockly.FieldCheckbox(
              prop.value,
              undefined,
              undefined
            );
            break;
          default:
            console.log("no field for type", prop.type);
            break;
        }
        dummyInput.appendField(fieldToAppend, "value");
      }

      // this.appendValueInput('attributes').setCheck(["attribute"]).setAlign(Blockly.ALIGN_RIGHT);
    },
  };

  const gen = HTMLGenerator as any;
  gen[blockName] = codeGenerator(blockName);

  return blockXML;
};

const generateBlocksForElement = (
  element: { properties: any; category: string },
  elementName: any
) => {
  const blockName = `${elementName}`;
  const category = generateToolboxCategoryForElement(elementName);
  const blockXML = document.createElement("block");

  blockXML.setAttribute("type", blockName);
  category.appendChild(blockXML);

  for (const propName in element.properties) {
    const attributeXML = generateAttributeBlockForElement(
      element,
      elementName,
      propName
    );
    if (attributeXML) {
      category.appendChild(attributeXML);
    }
  }

  const def = {
    type: "htmlElement",

    previousStatement: "htmlElement",
    nextStatement: "htmlElement",
    colour: 190,
  };

  Blockly.Blocks[blockName] = {
    def: def,

    init: function () {
      this.jsonInit(def);

      const color: any = colorForTag[element.tag];
      console.log(color);
      if (color) {
        this.setColour(color.h);
      }

      this.setOutput(false, "htmlElement");

      addComposeToSingleBlockField(this);
      // produce a label that contains the element's name
      const dummyInput = this.appendDummyInput();
      dummyInput.appendField(elementName);

      // statementInput for attributes
      this.appendStatementInput("attributes")
        .appendField("attributes:")
        .setCheck(["attribute"]);

      // statementInput for content
      this.appendStatementInput("content")
        .appendField("content:")
        .setCheck(["htmlElement"]);
    },
  };

  const gen = HTMLGenerator as any;
  gen[elementName] = codeGenerator(elementName);
  return category;
};

export { generateBlocksForElement };
