import { HTMLGenerator } from "./generators/HTMLGenerator.js";
import { tools } from "./generators/toolbox.js";

import Blockly from "blockly";

import { CustomRenderer } from "./renderer/CustomRenderer.js";
import { GenericField } from "./inputFields/CustomInputField";

export { HTMLGenerator, tools, Blockly, CustomRenderer, GenericField };

const previewButton = document.getElementById("previewButton") as HTMLElement;
const preview = document.getElementById("preview") as HTMLElement;

previewButton?.addEventListener("click", () => {
  var code = HTMLGenerator.workspaceToCode(workspace);
  preview.innerHTML = code;
});

// const generatePreview = () => {
//   var code = HTMLGenerator.workspaceToCode(workspace);
//   preview.innerHTML = code;
// };

const combinedContainer = document.getElementById(
  "combinedContainer"
) as HTMLElement;
const editorContainer = document.getElementById(
  "editorContainer"
) as HTMLElement;

// var blocklyArea = document.getElementById("blocklyArea");
var blocklyTools = document.getElementById("blocklyTools") as HTMLElement;

let isDragging = false;
const resizer = document.getElementById("dragMe");
resizer?.addEventListener("mouseover", () => {
  document.body.classList.add("mouseDrags");
});
resizer?.addEventListener("mouseout", () => {
  if (!isDragging) document.body.classList.remove("mouseDrags");
});
resizer?.addEventListener("mousedown", () => {
  isDragging = true;
  document.body.classList.add("mouseDrags");
});
document.body.addEventListener("mousemove", (e) => {
  if (isDragging) {
    // console.log(e.pageX, e.pageY);
    let rect = combinedContainer?.getBoundingClientRect() || {
      left: 0,
      right: 0,
      width: 0,
    };
    let pos = e.pageX - rect.left;
    let percentage = (pos / rect.width) * 100;

    editorContainer.style.width = percentage + "%";

    // onresize();
    Blockly.svgResize(workspace);
  }
});
document.body.addEventListener("mouseup", () => {
  isDragging = false;
  document.body.classList.remove("mouseDrags");
});

blocklyTools.appendChild(tools);

function loadWorkspace(button) {
  let workspace = Blockly.getMainWorkspace();
  workspace.clear();
  if (button.blocklyXml) {
    Blockly.Xml.domToWorkspace(button.blocklyXml, workspace);
  }
}

// about injection options, see https://developers.google.com/blockly/guides/configure/web/configuration_struct
var workspace = Blockly.inject(blocklyTools, {
  toolbox: document.getElementById("toolbox"),
  collapse: true,
  renderer: "zelos",
  // renderer: "custom_renderer"
});

var onresize = function () {
  // Compute the absolute coordinates and dimensions of blocklyArea.
  // var element = blocklyArea;
  // var x = 0;
  // var y = 0;
  // do {
  //     x += element.offsetLeft;
  //     y += element.offsetTop;
  //     element = element.offsetParent;
  //     console.log(x, y);
  // } while (element);

  // // Position blocklyTools over blocklyArea.
  // blocklyTools.style.left = x + 'px';
  // blocklyTools.style.top = y + 'px';
  // blocklyTools.style.width = blocklyArea.offsetWidth + 'px';
  // blocklyTools.style.height = blocklyArea.offsetHeight + 'px';
  Blockly.svgResize(workspace);
};

window.addEventListener("resize", onresize, false);
onresize();
Blockly.svgResize(workspace);

// generating a context menu for blocks *************************************

const m = {
  id: "1",
  displayText: "help",
  callback: () => {
    // todo
  },

  preconditionFn: () => {
    return "enabled";
  },
  scopeType: Blockly.ContextMenuRegistry.ScopeType.BLOCK,
};

// make sure the singleton class is instantiated

let registry = new Blockly.ContextMenuRegistry();
registry.register(m);

// **************************************************************************

function myUpdateFunction(event: any) {
  const code = HTMLGenerator.workspaceToCode(workspace);

  console.log(code);
  // document.getElementById('sourcecode').innerText = code;
  // document.getElementById('website').src = "data:text/html;charset=utf-8," + encodeURIComponent(code);

  // Save Blocks
  const xml = Blockly.Xml.workspaceToDom(workspace);
  const xml_text = Blockly.Xml.domToText(xml);
  localStorage.setItem("blockly-html-code", xml_text);
}
workspace.addChangeListener(myUpdateFunction);

// var checkboxes = workspace.newBlock('aa-checkboxes');         // create new instance of generic block
// checkboxes.initModel();
// checkboxes.initSvg();
// checkboxes.render();

// var item1 = workspace.newBlock('aa-choice-item');         // create new instance of generic block
// item1.initModel();
// item1.initSvg();
// item1.render();
// var item1Value = workspace.newBlock('aa-choice-item-value');
// item1Value.initModel();
// item1Value.initSvg();
// item1Value.getField('value').setValue("1");
// item1Value.render();
// item1.getInput('attributes').connection.connect(item1Value.previousConnection);
// var item1Text = workspace.newBlock('textNode');
// item1Text.initModel();
// item1Text.initSvg();
// item1Text.getField("content").setValue("First choice");
// item1Text.render();
// item1.getInput('content').connection.connect(item1Text.previousConnection);

// var item2 = workspace.newBlock('aa-choice-item');         // create new instance of generic block
// item2.initModel();
// item2.initSvg();
// item2.render();
// var item2Value = workspace.newBlock('aa-choice-item-value');
// item2Value.initModel();
// item2Value.initSvg();
// item2Value.getField('value').setValue("2");
// item2Value.render();
// item2.getInput('attributes').connection.connect(item2Value.previousConnection);
// var item2Text = workspace.newBlock('textNode');
// item2Text.initModel();
// item2Text.initSvg();
// item2Text.getField("content").setValue("Second choice");
// item2Text.render();
// item2.getInput('content').connection.connect(item2Text.previousConnection);

// var item3 = workspace.newBlock('aa-choice-item');         // create new instance of generic block
// item3.initModel();
// item3.initSvg();
// item3.render();
// var item3Value = workspace.newBlock('aa-choice-item-value');
// item3Value.initModel();
// item3Value.initSvg();
// item3Value.getField('value').setValue("3");
// item3Value.render();
// item3.getInput('attributes').connection.connect(item3Value.previousConnection);
// var item3Text = workspace.newBlock('textNode');
// item3Text.initModel();
// item3Text.initSvg();
// item3Text.getField("content").setValue("Third choice");
// item3Text.render();
// item3.getInput('content').connection.connect(item3Text.previousConnection);

// checkboxes.getInput("content").connection.connect(item1.previousConnection);
// item1.nextConnection.connect(item2.previousConnection);
// item2.nextConnection.connect(item3.previousConnection);

// generatePreview();

var customItem = workspace.newBlock("htmlBlock");
customItem.initModel();
customItem.initSvg();
customItem.render();

// const fO= document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
// // this.fO.setAttribute("requiredExtensions", "http://example.com/SVGExtensions/EmbeddedXHTML");
// fO.setAttribute("x",0);
// fO.setAttribute("y",0);
// fO.setAttribute("width",500);
// fO.setAttribute("height",500);

// const div = document.createElement("div");
// div.setAttribute('xmlns', 'http://www.w3.org/1999/xhtml')
// div.innerHTML = `hey!`;
// fO.appendChild(div);
// document.getElementById("lala").appendChild(fO);
// // this.borderRect_ = this.fO;
// // console.log(this.borderRect_.parentElement?.outerHTML);
